import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Avatar, Space, Tooltip} from 'antd';
import {ChevronDownIcon} from '../../icons';
import {
  StyledNavigationBottomNavigation,
  StyledNavigationCompanyDetails,
  StyledNavigationCompanyName,
  StyledNavigationDropdownContainer,
  StyledNavigationDropdown,
  StyledNavigationMenu,
  StyledNavigationMenuSpin,
  StyledNavigation
} from './StyledNavigation';
import DropdownNavigation from './DropdownNavigation';
import {useOnClickOutside} from '../../hooks';
import './Navigation.css';
import {mainActions} from '../../state/actions/main.actions';
import {KYCModalModeTypes} from '../../constants';
import {ReactComponent as WithLessIconLogo} from '../../static/images/withless-icon-logo.svg';
import {ReactComponent as WithLessLogo} from '../../static/images/withless-logo.svg';
import {elementHelpers, textHelpers} from '../../utils/helpers';

const Navigation = ({
  company,
  dividedKeys,
  items,
  isOpenMenu,
  loading,
  openKycWindow,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const menuItems = useMemo(() => {
    const topItems = items.filter(i => !dividedKeys.includes(i.key));
    const bottomItems = items.filter(i => dividedKeys.includes(i.key));
    let menuItems = [...topItems];
    if (Boolean(bottomItems)) {
      menuItems = [
        ...menuItems,
        bottomItems.length && {type: 'divider'},
        ...bottomItems
      ];
    }
    return menuItems;
  }, [dividedKeys, items]);

  const collapsedClassName = useMemo(() => collapsed ? 'collapsed' : '', [collapsed]);

  useEffect(() => {
    if (isOpenMenu === collapsed) setCollapsed(!isOpenMenu);
  }, [isOpenMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    elementHelpers.updateMenuItems({location, items, selectedKeys, setSelectedKeys});
  }, [location, items]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCompanyChange = () => {
    navigate('/');
  }

  const onDropdownNavigationClick = () => {
    setIsOpenDropdown(false);
  }

  const handleMenuClick = (e) => {
    const {key} = e;
    const item = items.find(i => i.key === key);
    if (item) navigate(item.path);
    setSelectedKeys([key]);
  }

  const onClickOutside = (e) => {
    const parentSelector = '.ant-dropdown';
    if (!e.target.closest(parentSelector)) setIsOpenDropdown(false);
  }

  const onAddCompany = () => {
    onDropdownNavigationClick();
    openKycWindow({mode: KYCModalModeTypes.COMPANY});
  }

  const getCompanyDetails = (avatarSize) => {
    const name = company?.name || '';
    const truncatedName = textHelpers.cutString(name, 35);
    const showTooltip = truncatedName.length < name.length && isOpenMenu;

    return (
      <StyledNavigationCompanyDetails>
        <Avatar
          src={company?.logo}
          size={avatarSize}
        >
          {textHelpers.getInitials(name)}
        </Avatar>
        <StyledNavigationCompanyName truncated={showTooltip}>
          {showTooltip ? (
            <Tooltip title={name}>{truncatedName}</Tooltip>
          ) : truncatedName}
        </StyledNavigationCompanyName>
      </StyledNavigationCompanyDetails>
    )
  };

  return (
    <StyledNavigation {...rest}>
      <div>
        <StyledNavigationDropdownContainer
          className={collapsedClassName}
          ref={useOnClickOutside(onClickOutside, isOpenDropdown)}
        >
          <StyledNavigationDropdown
            className={collapsedClassName}
            disabled={collapsed}
            dropdownRender={() => (
              <DropdownNavigation
                enabledCompanyCreation={false}
                onCompanyChange={onCompanyChange}
                onMenuClick={onDropdownNavigationClick}
                handleAddCompany={onAddCompany}
              />
            )}
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            open={isOpenDropdown}
            overlayClassName='navigation-dropdown-overlay'
            trigger={['click']}
          >
            <Space className='d-flex justify-space-between'>
              {getCompanyDetails(24)}
              <ChevronDownIcon />
            </Space>
          </StyledNavigationDropdown>
        </StyledNavigationDropdownContainer>
        <StyledNavigationMenuSpin size='small' spinning={loading}>
          <StyledNavigationMenu
            inlineCollapsed={collapsed}
            items={menuItems}
            mode='inline'
            onClick={handleMenuClick}
            selectedKeys={selectedKeys}
          />
        </StyledNavigationMenuSpin>
      </div>
      <StyledNavigationBottomNavigation>
        <Link to='/' className='d-inline-flex'>
          {collapsed ? <WithLessIconLogo /> : <WithLessLogo />}
        </Link>
      </StyledNavigationBottomNavigation>
    </StyledNavigation>
  );
}

Navigation.propTypes = {
  dividedKeys: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disable: PropTypes.bool,
      key: PropTypes.any.isRequired,
      icon: PropTypes.object,
      isUnread: PropTypes.bool,
      label: PropTypes.string.isRequired,
      path: PropTypes.string || null
    })
  ).isRequired,
  loading: PropTypes.bool
}

Navigation.defaultProps = {
  dividedKeys: [],
  items: [],
  loading: false,
}

const mapStateToProps = state => {
  const {company, main} = state;
  const {isOpenMenu} = main;
  return {
    company,
    isOpenMenu
  }
}

const mapDispatchToProps = {
  openKycWindow: mainActions.openKycWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
