import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Skeleton} from 'antd';
import {StyledNotifications, StyledSwitch, StyledToggle} from './StyledNotifications';
import UserInfo from '../../tabComponents/UserInfo';
import {userActions} from '../../../../../state/actions/user.actions';
import {useContentLoaded} from '../../../../../hooks';
import {elementHelpers, objectHelpers, systemHelpers} from '../../../../../utils/helpers';
import {firebaseEvents} from '../../../../../snippets/firebase';


const Notifications = ({
  dispatch,

  isAdmin,
  settings,

  getSettings,
  updateEmployeeEmailNotifications,

  ...rest
}) => {
  const [trans] = useTranslation(['profile', 'main']);
  const isLoaded = useContentLoaded(settings);
  const [formState, setFormState] = useState({});

  const t = (key) => trans(`tabs.notifications.emailNotifications.${key}`);

  useEffect(() => getSettings(), [getSettings]);

  const emailToggles = useMemo(() => {
    let adminToggles = [
      { key: 'low_balance', options: ['low_balance'] },
      { key: 'subscription_about_to_renew', options: ['subscription_about_to_renew'] },
      { key: 'subscription_transactions', options: ['subscription_transactions'] },
      { key: 'missing_invoices_daily', options: ['missing_invoices_daily'] },
      { key: 'missing_invoices_monthly_admin', options: ['missing_invoices_monthly'] },
      { key: 'missing_transactions_summary', options: ['missing_transactions_summary'] },
      { key: 'all_notifications', options: ['all_notifications'] }
    ];
    let userToggles = [
      { key: 'missing_invoices_daily', options: ['missing_invoices_daily'] },
      { key: 'missing_invoices_monthly_user', options: ['missing_invoices_monthly'] },
    ];
    return isAdmin ? adminToggles : userToggles;
  }, [isAdmin]);

  useEffect(() => {
    if (!objectHelpers.isEmptyObject(settings)) {
      const notificationSettings = settings?.email_notification_settings || {};

      const formSettings = emailToggles.reduce((acc, { key, options }) => {
        const values = options.map(option => notificationSettings[option]);
        acc[key] = values.filter(Boolean).length > values.length / 2;
        return acc;
      }, {});

      setFormState(formSettings);
    }
  }, [emailToggles, settings]);

  const getToggleOptions = (key, value) => {
    const toggle = emailToggles.find(t => t.key === key);
    if (!toggle) return {}; // Return empty object if key is not found

    return toggle.options.reduce((acc, option) => {
      acc[option] = value;
      return acc;
    }, {});
  }

  const onToggleChange = (e, stateName) => {
    const value = !formState[stateName];
    const formData = {email_notification_settings: getToggleOptions(stateName, value)};

    setFormState({...formState, [stateName]: value });

    systemHelpers.logEvent(firebaseEvents.PROFILE_SETTINGS_UPDATE_EMAIL_NOTIFICATIONS);

    updateEmployeeEmailNotifications(formData, getSettings);
  }

  const showContent = (content, loader) => elementHelpers.showContentWithLoader(isLoaded, content, loader);

  const showToggle = (stateName, path) => (
    <div data-testid={stateName}>
      {showContent(
        <StyledSwitch
          checked={formState[stateName]}
          onChange={(e) => onToggleChange(e, stateName, path)}
        />,
        <Skeleton.Button active />
      )}
    </div>
  );

  const getToggles = () => {
    return emailToggles.map(({key, ...rest}) => ({
      ...rest,
      key,
      description: t(`${key}.description`),
      title: t(`${key}.title`),
      toggle: showToggle(key)
    }));
  }

  const toggles = getToggles();

  return (
    <StyledNotifications
      title={<UserInfo />}
      {...rest}
    >
      {toggles.map(t => (
        <StyledToggle key={t.key}>
          <div>
            <h3>{t.title}</h3>
            <p>{t.description}</p>
          </div>
          {t.toggle}
        </StyledToggle>
      ))}
    </StyledNotifications>
  );
}

const mapStateToProps = ({ user }) => ({
  isAdmin: user.isAdmin,
  settings: user.notificationSettings,
});

const mapDispatchToProps = {
  getSettings: userActions.getEmployeeNotificationSettings,
  updateEmployeeEmailNotifications: userActions.updateEmployeeEmailNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
