import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {
  StyledTableFilters,
  StyledTableFiltersCardSelect,
  StyledTableFiltersDivider,
  StyledTableFiltersSearch,
} from './StyledTableFilters';
import ExportModal from '../ExportModal';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow/AuthenticationWindow';
import {useIsMount} from '../../../../hooks';
import {expenseActions} from '../../../../state/actions/expense.actions';
import {transactionActions} from '../../../../state/actions/transaction.actions';
import {formHelpers, objectHelpers, scaHelpers, systemHelpers} from '../../../../utils/helpers';
import {cardPaymentStatusesConstants, SCAActionsConstants} from '../../../../constants';
import {firebaseEvents} from '../../../../snippets/firebase';

const {logEvent} = systemHelpers;

const SCA_OPERATION_NAME = SCAActionsConstants.TRANSACTIONS_DOWNLOAD_PDF_AND_CSV_ACTION;

const paymentOptions = {
  ALL: 'all',
  UNMATCHED: 'unmatched',
}
const {ALL, UNMATCHED} = paymentOptions;

const exportTimeFormat = 'YYYY-MM-DD';

const getPaymentStatusValue = (value) => {
  let paymentStatus = [];
  let hasInvoice = undefined;
  if (value === UNMATCHED) {
    hasInvoice = false;
  } else {
    paymentStatus = value === ALL ? [] : [value];
  }
  return {
    payment_status: paymentStatus,
    has_invoice: hasInvoice
  }
}

const getPaymentStatusFieldValue = (filter) => {
  let hasInvoice = filter?.has_invoice;
  const paymentStatus = filter?.payment_status;
  let value = String(hasInvoice) === String(false) ? UNMATCHED : Array.isArray(paymentStatus) ? paymentStatus[0] : paymentStatus;
  return value || ALL;
}

const validateDateRange = (dateRange) => dateRange && dateRange.length === 2;

const selectProps = {
  allowClear: true,
  maxTagCount: 1,
  mode: 'multiple',
  size: 'large',
  showSearch: true
}

const TableFilters = ({
  enableExport,
  onFilter,
  searchParams,

  cards,
  employees,
  employeeEmail,
  isAdmin,
  isKnownEmployee,

  downloadTransactions,
  downloadZipInvoices,
}) => {
  const [t] = useTranslation(['main', 'cards', 'transactions']);
  const [exportModalProps, setExportModalProps] = useState({loading: false, open: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [exportDetails, setExportDetails] = useState({range: undefined, format: null});

  const [filter, setFilter] = useState({
    card_id_in: [],
    has_invoices: undefined,
    payment_status: undefined,
    search: '',
    user_id_in: []
  });
  const isMounted = !useIsMount();

  useEffect(() => {
    if (isKnownEmployee) {
      if (searchParams) {
        setFilter(objectHelpers.getSearchParams(searchParams));
      } else {
        handleFilter(filter);
      }
    }
  }, [isKnownEmployee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => isMounted && handleFilter(filter), [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardOptions = useMemo(() => formHelpers.getCardsOptions(cards), [cards]);

  const employeeOptions = useMemo(() =>
    formHelpers.getEmployeeOptions({employees, employeeEmail, t, valueKey: 'employee_id'}), [employees, employeeEmail, t]);

  const paymentStatusOptions = [
    {label: t('all'), value: ALL},
    {label: t('missingInvoices'), value: UNMATCHED},
    {label: t('cardPaymentStatuses.declined'), value: cardPaymentStatusesConstants.DECLINED}
  ];

  const paymentStatusValue = useMemo(() => getPaymentStatusFieldValue(filter), [filter]);

  const blockedExportFormat = useMemo(() => paymentStatusValue === ALL ? undefined : 'zip', [paymentStatusValue]);

  const handleFilter = (filterData) => onFilter && onFilter(filterData);

  const handleOnFilter = (key, value) => setFilter({...filter, [key]: value});

  const handleChangePaymentStatus = (value) => {
    setFilter({
      ...filter,
      ...getPaymentStatusValue(value)
    });
  }

  const handleExportErrorCallback = () => setExportModalProps({...exportModalProps, open: true, loading: false});

  const handleExportZip = (data) => {
    const {date_range: dateRange} = data;

    const isValidDateRange = validateDateRange(dateRange);

    if (!isValidDateRange) return;

    const userEmails = [].concat(filter?.user_id_in || [])
      .map(id => employees.find(e => e.employee_id === id)?.email)
      .filter(Boolean);

    const query = {
      date_gte: dateRange[0].format(exportTimeFormat),
      date_lte: dateRange[1].format(exportTimeFormat),
      user_email_in: userEmails,
      ...objectHelpers.removeKeysFromObject(filter, ['has_invoices', 'payment_status', 'user_id_in'])
    };

    downloadZipInvoices(
      query,
      (data) => {
        window.open(data.url, 'download');
        handleCancelExportModal();
        logEvent(firebaseEvents.TRANSACTIONS_DOWNLOAD_ZIP_INVOICES);
      },
      handleExportErrorCallback
    );
  }

  const handleExportTransactions = (data) => {
    const {format, date, date_range: dateRange} = data;

    const isValidDateRange = validateDateRange(dateRange);

    const [startDate, endDate] = format === 'csv' && isValidDateRange
      ? dateRange
      : [date.startOf('month'), date.endOf('month')];

    const query = {
      date_gte: startDate.format(exportTimeFormat),
      date_lte: endDate.format(exportTimeFormat),
      file_format: format,
      ...(format === 'csv' && filter)
    };

    const downloadLogEvents = {
      csv: firebaseEvents.TRANSACTIONS_EXPORT_CSV,
      pdf: firebaseEvents.TRANSACTIONS_EXPORT_PDF
    };

    const downloadLogEvent = downloadLogEvents[format];

    downloadTransactions({
      headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
      query,
      successCallback: (data) => {
        window.open(data.url, 'download');
        handleCancelExportModal();
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => {
            setAuthWindowProps({...authWindowProps, ...scaProps});
            setExportDetails(data);
            downloadLogEvent && logEvent(downloadLogEvent);
          },
          handleExportErrorCallback
        });
      }
    });
  }

  const handleOkExportModal = (data) => {
    setExportModalProps({...exportModalProps, loading: true});

    const exportHandler = data.format === 'zip' ? handleExportZip : handleExportTransactions;
    exportHandler(data);
  }

  const handleOnExportClick = () => setExportModalProps({...exportModalProps, open: true});

  const handleCancelExportModal = () => setExportModalProps({...exportModalProps, open: false, loading: false});

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    setExportModalProps({...exportModalProps, loading: false});
  }

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    handleOkExportModal(exportDetails);
  }

  return (
    <StyledTableFilters align='center' wrap>
      <Space align='center' size='small' wrap>
        <StyledTableFiltersSearch
          allowClear
          onSearch={(value) => handleOnFilter('search', value)}
          placeholder={t('search')}
          value={filter.search}
          size='large'
        />
        <StyledTableFiltersCardSelect
          {...selectProps}
          onChange={(value) => handleOnFilter('card_id_in', value)}
          optionFilterProp='label'
          optionLabelProp='optionLabel'
          options={cardOptions}
          placeholder={t('transactions:tableFilters.placeholder.cards')}
          value={filter?.card_id_in ?? []}
        />
        {isAdmin && (
          <StyledTableFiltersCardSelect
            {...selectProps}
            dropdownStyle={{minWidth: 235}}
            onChange={(value) => handleOnFilter('user_id_in', value)}
            optionFilterProp='optionLabel'
            optionLabelProp='optionLabel'
            options={employeeOptions}
            placeholder={t('cards:tableFilters.placeholder.users')}
            value={filter?.user_id_in ?? []}
          />
        )}
        <StyledTableFiltersCardSelect
          onChange={handleChangePaymentStatus}
          options={paymentStatusOptions}
          size='large'
          value={paymentStatusValue}
        />
      </Space>
      {(isAdmin && enableExport) && (
        <>
          <Space size={0}>
            <StyledTableFiltersDivider type='vertical' />
            <Button
              onClick={handleOnExportClick}
              size='large'
              type='primary'
            >
              {t('export')}
            </Button>
          </Space>

          <ExportModal
            {...exportModalProps}
            blockedFormat={blockedExportFormat}
            handleCancel={handleCancelExportModal}
            handleOk={handleOkExportModal}
            title={`${t('export')} ${t('transactions')}`}
          />

          <AuthenticationWindow
            {...authWindowProps}
            handleCancel={handleCloseAuthModal}
            onSuccess={handleOnSuccessAuth}
            operationName={SCA_OPERATION_NAME}
          />
        </>
      )}

    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  enableExport: PropTypes.bool,
  onFilter: PropTypes.func,
  searchParams: PropTypes.object,
}

TableFilters.defaultProps = {
  enableExport: true
}

const mapStateToProps = ({ user: { employee, isKnownEmployee, isAdmin }, card: { cardsSummaries }, company: { employees } }) => ({
  cards: cardsSummaries,
  employees,
  employeeEmail: employee?.email ?? null,
  isKnownEmployee,
  isAdmin
});

const mapDispatchToProps = {
  downloadTransactions: transactionActions.downloadTransactions,
  downloadZipInvoices: expenseActions.downloadZipInvoices
}

export default connect(mapStateToProps, mapDispatchToProps)(TableFilters);
