import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Select} from 'antd';
import {
  StyledTableFilters,
  StyledFilterElements,
  StyledRightSideContent,
  StyledFilterElementsSpace
} from './StyledTableFilters';
import {cardsHelpers, CardTypesOptions, formHelpers} from '../../../../utils/helpers';

const selectProps = {
  dropdownStyle: {minWidth: 235},
  maxTagCount: 2,
  mode: 'multiple',
  size: 'large',
  showSearch: true
}

const TableFilters = ({
  dispatch,
  defaultSearch,
  defaultQuery,
  filterFields,
  onFilter,
  rightSideContent,

  cards,
  costCenterTags,
  employees,
  employeeEmail,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [cardTypesOptions, ] = useState([
    {label: 'All types', value: ''},
    ...CardTypesOptions()
  ]);
  const [values, setValues] = useState({
    assigned_to: '',
    card_numbers: [],
    card_owners: [],
    cost_centers: [],
  });

  const tPlaceholder = (key) => t(`cards:tableFilters.placeholder.${key}`);

  const cardOptions = useMemo(() => formHelpers.getCardsOptions(cards), [cards]);

  const employeeOptions = useMemo(() =>
    formHelpers.getEmployeeOptions({employees, employeeEmail, t}), [employees, employeeEmail, t]);

  const tagOptions = useMemo(() => formHelpers.getTagOptions(costCenterTags), [costCenterTags]);

  useEffect(() => setValues({...values, ...defaultQuery}), [defaultQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEnabledField = (key) => filterFields.includes(key);

  const handleOnFilter = (key, value) => {
    setValues({...values, [key]: value});
    onFilter && onFilter({[key]: value});
  }

  return (
    <StyledTableFilters {...rest}>
      <StyledFilterElements>
        <StyledFilterElementsSpace
          enabledRightSideContent={rightSideContent !== undefined}
          size='small'
        >
          {isEnabledField('card_numbers') && (
            <Select
              {...selectProps}
              allowClear
              className='table-filters__cards-select'
              onChange={(value) => handleOnFilter('card_numbers', value)}
              options={cardOptions}
              optionFilterProp='label'
              optionLabelProp='optionLabel'
              placeholder={tPlaceholder('cards')}
              value={values.card_numbers}
            />
          )}
          {isEnabledField('card_owners') && (
            <Select
              {...selectProps}
              onChange={(value) => handleOnFilter('card_owners', value)}
              optionFilterProp='optionLabel'
              optionLabelProp='optionLabel'
              options={employeeOptions}
              placeholder={t('Owner')}
              value={values.card_owners}
            />
          )}
          {isEnabledField('cost_centers') && (
            <Select
              {...selectProps}
              onChange={(value) => handleOnFilter('cost_centers', value)}
              options={tagOptions}
              placeholder={t('costCenter')}
              value={values.cost_centers}
            />
          )}
          {isEnabledField('assigned_to') && (
            <Select
              onChange={(value) => handleOnFilter('assigned_to', value)}
              options={cardTypesOptions}
              size='large'
              value={values.assigned_to}
            />
          )}
          {rightSideContent && <StyledRightSideContent>{rightSideContent}</StyledRightSideContent>}
        </StyledFilterElementsSpace>
      </StyledFilterElements>
    </StyledTableFilters>
  );
}

TableFilters.propTypes = {
  defaultSearch: PropTypes.string,
  defaultQuery: PropTypes.object,
  filterFields: PropTypes.arrayOf(PropTypes.oneOf(['assigned_to', 'card_numbers', 'card_owners', 'cost_centers'])),
  onFilter: PropTypes.func,
  rightSideContent: PropTypes.any
}

TableFilters.defaultProps = {
  defaultSearch: '',
  defaultQuery: {},
  filterFields: ['tags']
}

const mapStateToProps = state => {
  const {cardsSummaries} = state.card;
  const {costCenterTags, employees} = state.company;
  const {employee} = state.user;
  return {
    cards: cardsHelpers.filterValidCards(cardsSummaries),
    costCenterTags,
    employees,
    employeeEmail: employee.email,
  }
}

export default connect(mapStateToProps, null)(TableFilters);
