import styled from 'styled-components';
import {Dropdown, Menu, Space} from 'antd';
import {bordersStyles, typographyStyles} from '../../utils/styles';
import Spin from '../Spin';

const StyledNavigation = styled.div`
  background-color: ${props => props.theme.colors.additional_bg_2};
  border-right: 1px solid ${props => props.theme.colors.neutral_4};
  color: ${props => props.theme.colors.neutral_1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  transition: .3s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.neutral_1};
  }
`;

const StyledNavigationDropdownContainer = styled.div`
  padding: 8px;
  transition: padding .3s ease;
  &.collapsed {
    padding: 8px 8px 8px 6px;
  }
`;

const StyledNavigationCompanyDetails = styled(Space)`
  ${typographyStyles.heading.h5};
  color: ${props => props.theme.colors.neutral_10};
  gap: 10px !important;
  
  & .ant-space-item {
    line-height: 18px;
    position: relative;
  }
`;

const StyledNavigationCompanyName = styled.span`
  display: block;
  line-height: 16px;
  min-width: 135px;
  font-size: ${props => props.truncated ? '14px' : '16px'};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledNavigationBottomNavigation = styled.div`
  padding: 16px 24px;
  position: relative;
  
  svg {
    rect {
      transition: .3s ease-in-out;
    }
  }
  
  a {
    &:hover,
    &:active {
      svg {
        rect {
          &:nth-of-type(1) {
            fill: ${props => props.theme.colors.additional_sky};
          }
          &:nth-of-type(2) {
            fill: ${props => props.theme.colors.additional_apple};
          }
          &:nth-of-type(3) {
            fill: ${props => props.theme.colors.primary_6};
          }
          &:nth-of-type(4) {
            fill: ${props => props.theme.colors.additional_neon};
          }
        }
      }
    }
  }
  
  &::before {
    border-top: 1px solid ${props => props.theme.colors.neutral_3};
    content: '';
    position: absolute;
    left: 8px;
    transform: translateY(-1rem);
    width: calc(100% - 8px * 2);
  }
`;

const StyledNavigationMenu = styled(Menu)`
  background-color: transparent;
  margin-top: 16px;
  padding: 0 8px;
  
  &.ant-menu-inline {
    border-inline-end: none !important;
  }
  
  &.ant-menu-inline-collapsed {
    border-inline-end: none !important;
    width: 72px;
    li.ant-menu-item {
      & > svg {
        margin-left: 3px;
        &+span {
          display: inline-block;
          opacity: 0;
        }
      }
    }
  }
  
  .ant-menu-title-content {
    ${typographyStyles.body.medium};
  }
  
  li.ant-menu-item {
    color: ${props => props.theme.colors.neutral_9};
    margin: 0;
    padding-left: 16px !important;
    position: relative;
    width: 100%;

    & > svg {
      height: 16px;
      margin-right: 10px;
      width: 16px;
      & path {
        fill: ${props => props.theme.colors.neutral_10};
        transition: .3s ease;
      }
    }

    &.ant-menu-item-selected {
      background-color: ${props => props.theme.colors.primary_1};
      color: ${props => props.theme.colors.primary_6};
      & > svg {
        & path {
          fill: ${props => props.theme.colors.primary_7};
        }
      }
    }
    &.ant-menu-item-disabled {
      & > svg {
        & path {
          fill: rgba(0, 0, 0, 0.25)!important;
        }
      }
    }
    &:not(.ant-menu-item-selected) {
      &:focus,&:active {
        background-color: ${props => props.theme.colors.primary_2} !important;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
  
  .ant-menu-item-divider {
    border-color: ${props => props.theme.colors.neutral_4};
    margin-block: 8px;
  }
`;

const StyledNavigationDropdownMenu = styled(Menu)`
  &.ant-dropdown-menu {
    background-color: transparent;
    box-shadow: unset;
    margin-top: 16px;
    padding: 0;

    li.ant-dropdown-menu-item {
      padding: 9px 5px;
      & > svg {
        height: 16px;
        margin-right: 10px;
        width: 16px;
        & path {
          transition: .3s ease;
          &[stroke-linecap="round"] {
            stroke: ${props => props.theme.colors.neutral_10};
          }
          &:not([stroke-linecap="round"]) {
            fill: ${props => props.theme.colors.neutral_10};
          }
        }
      }
      &.ant-dropdown-menu-item-selected {
        background-color: ${props => props.theme.colors.primary_1};
        color: ${props => props.theme.colors.primary_6};
        & > svg {
          & path {
            &[stroke-linecap="round"] {
              stroke: ${props => props.theme.colors.primary_6}
            }
            &:not([stroke-linecap="round"]) {
              fill: ${props => props.theme.colors.primary_6}
            }
          }
        }
      }
      &.ant-dropdown-menu-item-disabled {
        & > svg {
          & path {
            fill: rgba(0, 0, 0, 0.25)!important;
          }
        }
      }
      
      .ant-dropdown-menu-title-content {
        ${typographyStyles.body.medium};
      }
    }
  }
`;

const StyledNavigationDropdown = styled(Dropdown)`
  ${bordersStyles.default};
  
  padding: 12px 16px;
  transition: .3s ease;
  
  svg {
    path {
      stroke: ${props => props.theme.colors.neutral_7};
      fill: ${props => props.theme.colors.neutral_7};
      transition: .3s ease;
    }
  }

  &:hover {
    border: 1px solid ${props => props.theme.colors.primary_5};
    cursor: pointer;
    
    svg {
      path {
        fill: ${props => props.theme.colors.primary_5};
        stroke: ${props => props.theme.colors.primary_5};
      }
    }
  }

  & .ant-space-item {
    display: flex;
    transition: .3s ease;
  }
  &.collapsed {
    border-color: transparent;
    &:hover {
      border: 1px solid ${props => props.theme.colors.primary_5};
      cursor: pointer;
    }
    & .ant-space-item {
      &:not(:first-of-type) {
        display: inline-block;
        opacity: 0;
      }
    }
  }
  &.ant-dropdown-open {
    background: linear-gradient(0deg, ${props => props.theme.colors.primary_1}, ${props => props.theme.colors.primary_1}),
                linear-gradient(0deg, ${props => props.theme.colors.primary_4}, ${props => props.theme.colors.primary_4});
    border: 1px solid ${props => props.theme.colors.primary_4};
    
    svg {
      path {
        fill: ${props => props.theme.colors.primary_5};
        stroke: ${props => props.theme.colors.primary_5};
      }
    }
  }
`;

const StyledNavigationDropdownContent = styled.div`
  ${bordersStyles.default};
  background-color: ${props => props.theme.colors.neutral_1};
  box-shadow: 0 9px 28px 8px #0000000D,
              0 6px 16px 0 #00000014,
              0 3px 6px -4px #0000001F;
  & > header {
    padding: 16px;
  }

  & > footer {
    align-items: center;
    border-top: 1px solid ${props => props.theme.colors.neutral_4};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 8px;

    button {
      width: calc(100% - 16px);
    }
  }
`;

const StyledNavigationCompaniesList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 140px;
  margin-bottom: 8px;
  overflow-x: auto;
  padding: 0 4px 0 4px;
  width: 100%;

  & > div {
    border-radius: ${props => props.theme.border_radius.standard};
    padding: 8px 4px;
    transition: .3s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
  }
`;

const StyledNavigationMenuSpin = styled(Spin)`
  max-height: unset !important;
`;

export {
  StyledNavigationBottomNavigation,
  StyledNavigationCompanyDetails,
  StyledNavigationCompanyName,
  StyledNavigationCompaniesList,
  StyledNavigationDropdownContainer,
  StyledNavigationDropdownContent,
  StyledNavigationDropdown,
  StyledNavigationMenu,
  StyledNavigationMenuSpin,
  StyledNavigation,
  StyledNavigationDropdownMenu
}
