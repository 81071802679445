import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Space, Tooltip} from 'antd'
import {connect} from 'react-redux';
import {
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants
} from '../../../../constants';
import CardDetailsButton from '../../CardsPage/CardDetailsButton';
import StyledSubscriptionPaymentType from './StyledSubscriptionPaymentType';
import {CreditCardIcon, CreditCardWarningIcon} from '../../../../icons';
import {bankingActions} from '../../../../state/actions/banking.actions';
import unusedCardIcon from '../../../../static/images/pages/subscriptions/unused-card-icon.png';
import {amountHelpers, cardsHelpers, objectHelpers} from '../../../../utils/helpers';

const tooltipOverlayInnerStyle = {
  maxWidth: 400,
  width: 'max-content'
};


const SubscriptionPaymentType = ({
  data,
  dispatch,
  employeeId,
  getUserCardImages,
  getCompanyInformation,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);

  const trans = (key, options) => t(`cards:${key}`, options);

  const gObjProp = (key) => objectHelpers.getObjProp(data, key);

  const budgetLimit = amountHelpers.getAmountWithCurrencyCode(gObjProp('budget_limit') || 0)
  const cardId = gObjProp('card_id');
  const cardMaskedPan = gObjProp('card_masked_pan');
  const paymentType = gObjProp('payment_type');
  const status = gObjProp('status');
  const cardOwnerId = gObjProp('card_owner', {})?.id || undefined;
  const ytg = gObjProp('ytg') || 0;
  const isCardUsed = ytg > 0;
  const isCardOwner = cardOwnerId === employeeId;

  const getTooltip = () => {
    if (cardId && cardMaskedPan) {
      let message = <>{cardsHelpers.hideCardNumber(cardMaskedPan)} | {t('limit')}: {budgetLimit}</>;
      if (!isCardUsed) {
        message = (
          <span className='d-flex align-items-center'>
            {message}&nbsp;
            |&nbsp;&nbsp;
            <span className='d-inline-flex align-items-center'>
              <img src={unusedCardIcon} alt='unused card icon' />&nbsp;&nbsp;{trans('unusedCard')}
            </span>
          </span>
        );
      }
      return message;
    } else if (status === subscriptionStatusesConstants.PENDING) {
      return trans('errors.hold');
    } else {
      return undefined;
    }
  }

  const cardTooltipTitle = getTooltip();

  const creditCardDetails = (
    <Space>
      <Tooltip
        overlayInnerStyle={tooltipOverlayInnerStyle}
        placement='bottom'
        title={cardTooltipTitle}
      >
        <Space>{(cardId && isCardUsed) ? <CreditCardIcon /> : <CreditCardWarningIcon />}</Space>
      </Tooltip>

      {(isCardOwner && cardId) && (
        <CardDetailsButton
          cardId={cardId}
          cardOwnerId={cardOwnerId}
        />
      )}
    </Space>
  );

  const getPaymentType = () => {
    if (status === subscriptionStatusesConstants.UNTRACKED) {
      return 'N.A.'
    }
    if (!objectHelpers.isNaV(paymentType)) {
      if (paymentType === subscriptionPaymentTypesConstants.CARD) {
        return creditCardDetails;
      } else {
        return t(`subscriptionPaymentTypes.${paymentType}`)
      }
    } else {
      return ''
    }
  }

  const value = getPaymentType();

  return (
    <StyledSubscriptionPaymentType {...rest}>
      {value}
    </StyledSubscriptionPaymentType>
  );
}

SubscriptionPaymentType.propTypes = {
  data: PropTypes.shape({
    card_id: PropTypes.string,
    card_masked_pan: PropTypes.string,
    payment_type: PropTypes.number,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    ytg: PropTypes.number
  })
}

const mapStateToProps = state => {
  const {id: employeeId} = state.user.employee;
  return {
    employeeId,
  }
}

const mapDispatchToProps = {
  getUserCardImages: bankingActions.getUserCardImages
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPaymentType);
