import {cardConstants} from '../constants';

const cardState = {
  cardsSummaries: [],
  storedCards: null,
};

const cardReducer = (state = cardState, action) => {
  const {type, data} = action;
  switch (type) {
    case cardConstants.CLEAR_CARDS_DATA:
      return {
        cardsSummaries: [],
        storedCards: null,
      }
    case cardConstants.STORE_CARDS:
      return {
        ...state,
        storedCards: data
      }
    case cardConstants.GET_CARDS_SUMMARIES:
      return {
        ...state,
        cardsSummaries: data
      }
    default:
      return state;
  }
};

export default cardReducer;
